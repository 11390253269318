import moment from 'moment';
import { useEffect } from 'react';

export default function doNothing() {
  // This is completely intentional
}

export const useClickOutside = ({ ref, callback, activeId, currentId }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        activeId === currentId // Ensure only the active component's callback is triggered
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, activeId, currentId]);
};

export const getItemExists = (list, { tfv, day }) => list.find(item => item.tfvId === tfv && item.day === moment.utc(day).format('YYYY-MM-DD'));

export const updateEntryData = (list, updatedPlan) => list.map((item) => {
  if (item.tfvId === updatedPlan.tfv && item.day === moment.utc(updatedPlan.day).format('YYYY-MM-DD')) {
    return {
      ...item,
      entryPlan: updatedPlan.plan.entryPlan.map(p => ({
        duration: p.duration,
        from: p.from,
        step: p.step,
        to: p.to,
        monitoringRate: p.monitoringRate ? Number(p.monitoringRate) : null,
        monitoringValue: p.monitoringValue ? Number(p.monitoringValue) : null,
      })),
    };
  }
  return item;
});

export const updateOccupancyData = (list, updatedPlan) => list.map((item) => {
  if (item.tfvId === updatedPlan.tfv && item.day === moment.utc(updatedPlan.day).format('YYYY-MM-DD')) {
    return {
      ...item,
      occPlan: updatedPlan.plan.occPlan.map(p => ({
        source: p.source,
        duration: p.duration,
        from: p.from,
        to: p.to,
        peak: p.peak ? Number(p.peak) : null,
        sustain: p.sustain ? Number(p.sustain) : null,
      })),
    };
  }
  return item;
});

// {
//   ...item,
//   occPlan: [{
//     source: replacedItem.source,
//     duration: replacedItem.duration,
//     from: replacedItem.from,
//     to: replacedItem.to,
//     peak: replacedItem.peak ? Number(replacedItem.peak) : null,
//     sustain: replacedItem.sustain ? Number(replacedItem.sustain) : null,
//   }],
// };
